import React, { useCallback, useEffect, useMemo } from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { ReactComponent as ChevronLeftIcon } from "../assets/icons/chevron-left.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/filter.svg";
import style from "../assets/scss/components/action-row.module.scss";
import { FILTER_NAME_MAX_LENGTH } from "../helpers/constants";
import { calendarRoute } from "../helpers/routes";
import { truncateString } from "../helpers/utils";
import {
  selectAccessibleArticleFilters,
  selectArticleFilterIsMobile,
  selectArticleFiltersLoading
} from "../store/articleFilter/selector";
import { toggleFilter, getFilters, activateAllNewsFilter } from "../store/articleFilter/slice";
import {
  selectDashboardFilterMenuOpen,
  selectDashboardActiveNewsItemColumnId
} from "../store/dashboard/selector";
import { toggleFilterMenu, markNewsItemAsActive } from "../store/dashboard/slice";

import PrimaryMenu from "./navigation/PrimaryMenu";
import SkeletonBox from "./skeleton/SkeletonBox";

function ActionRow({ filterVisibility }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filters = useSelector(selectAccessibleArticleFilters, isEqual);
  const loadingFilters = useSelector(selectArticleFiltersLoading, isEqual);
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);
  const filterMenuOpen = useSelector(selectDashboardFilterMenuOpen, isEqual);
  const activeNewsItemColumnId = useSelector(selectDashboardActiveNewsItemColumnId, isEqual);

  const isCalendarRoute = useMatch(calendarRoute);
  const isCalendarFilter = filterVisibility == "calendar";

  const visibleFilters = useMemo(
    () =>
      filters.filter(
        filter =>
          (isCalendarFilter
            ? filter.custom?.properties?.originalId === "all"
              ? false
              : true
            : true) && filter.visibility.includes(filterVisibility)
      ),
    [filterVisibility, filters, isCalendarFilter]
  );

  const anyFiltersActive = useMemo(
    () => visibleFilters.some(filter => (isCalendarFilter ? filter.calendarActive : filter.active)),
    [visibleFilters, isCalendarFilter]
  );

  useEffect(() => {
    if (!filters.length) {
      dispatch(getFilters());
    }
  }, [filters, dispatch]);

  const handleFilterClick = id => {
    dispatch(toggleFilter({ id, calendarFilter: isCalendarFilter }));

    const index = filters.findIndex(filter => filter.id === id);

    if (isCalendarFilter ? filters[index].activeInCalendar : filters[index].active) {
      if (activeNewsItemColumnId === id) {
        dispatch(markNewsItemAsActive({ id: null, columnId: null }));
      }
    }
  };

  const onClearFilters = useCallback(() => {
    dispatch(activateAllNewsFilter({ calendarFilter: isCalendarFilter }));
    dispatch(markNewsItemAsActive({ id: null, columnId: null }));
  }, [dispatch, isCalendarFilter]);

  return (
    <div className={classNames(style["action-row"])}>
      <button
        className={classNames(style["action-row__menu-trigger"], {
          [style["action-row__menu-trigger--open"]]: filterMenuOpen
        })}
        onClick={() => dispatch(toggleFilterMenu())}
      >
        <FilterIcon className={style["action-row__menu-trigger__icon"]} />
        <span className={style["action-row__menu-trigger__label"]}>Filter</span>
        <div className={style["action-row__menu-trigger__open-indicator"]}>
          <ChevronLeftIcon />
        </div>
      </button>

      {isMobile ? (
        <PrimaryMenu />
      ) : !loadingFilters ? (
        <>
          {isCalendarFilter && (
            <>
              <button
                className={classNames(style["action-row__button"], {
                  [style["action-row__button--active"]]: !anyFiltersActive
                })}
                onClick={onClearFilters}
              >
                {isCalendarRoute
                  ? t("articleCategories:AllEvents")
                  : t("articleCategories:AllNews")}
              </button>
              <div className={style["action-row__divider"]} />
            </>
          )}
          {visibleFilters.map(filter => (
            <React.Fragment key={filter.id}>
              <button
                className={classNames(style["action-row__button"], {
                  [style["action-row__button--active"]]: isCalendarFilter
                    ? filter.calendarActive
                    : filter.active
                })}
                key={filter.id}
                onClick={() => handleFilterClick(filter.id)}
              >
                {filter.default
                  ? filter.title == "articleCategories:All" && isCalendarRoute
                    ? t("articleCategories:AllEvents")
                    : t(filter.title)
                  : truncateString(filter.title, FILTER_NAME_MAX_LENGTH)}
              </button>
              {!isCalendarFilter && filter.custom?.properties?.originalId === "all" ? (
                <div className={style["action-row__divider"]} />
              ) : null}
            </React.Fragment>
          ))}
        </>
      ) : (
        [...Array(3)].map((value, index) => (
          <React.Fragment key={index}>
            <SkeletonBox
              style={{
                width: "66px",
                height: "26px",
                borderRadius: "3px"
              }}
            />
            <SkeletonBox
              style={{
                width: "90px",
                height: "26px",
                borderRadius: "3px"
              }}
            />
            <SkeletonBox
              style={{
                width: "45px",
                height: "26px",
                borderRadius: "3px"
              }}
            />
          </React.Fragment>
        ))
      )}
    </div>
  );
}

ActionRow.propTypes = {
  filterVisibility: PropTypes.string.isRequired
};

export default ActionRow;
