export const DEFAULT_DATE_FORMAT = "dd.LL.yyyy";
export const DEFAULT_SHOW_NUM_RESULTS = 20;
export const DEFAULT_SHOW_NUM_RESULTS_SINGLE_COLUMN = 60;
export const DEFAULT_CALENDAR_VIEW_MODE = "calendar";
export const FILTER_NAME_MAX_LENGTH = 16;
export const LS_KEY_INACTIVE_MY_NEWS_FILTERS = "INACTIVE_MY_NEWS_FILTERS";
export const ALL_NEWS_SERVICES = [
  "news",
  "mediation",
  "special",
  "election",
  "ntbtema",
  "forskning",
  "culture",
  "daytrivia",
  "omtaler",
  "tips"
  //"ticker",
  //"direct",
  //"npkdirect"
];

export const NTB_TEMA_NEWS_SERVICES = [
  "ntbtema",
  "special",
  "forskning",
  "tips",
  "daytrivia",
  "omtaler"
];

export const NTB_TEMA_SMALL_MATTER_CATEGORIES = [
  "Tippefakta",
  "TemaSpill",
  "Dagen i dag",
  "DiDNavnedag",
  "DiDFødselsdag",
  "DiDHendt",
  "DiDFloFjære",
  "DiDSolOppNed",
  "DiDDagensSitat",
  "Jubilant-omtaler",
  "Jubilantomtaler"
];

export const DEFAULT_FILTERS = [
  {
    id: "all",
    title: "articleCategories:All",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed", "calendar"],
    properties: {
      service: ["news"]
    },
    custom: {
      properties: {
        originalId: "all"
      }
    }
  },
  {
    id: "domestic",
    title: "articleCategories:Domestic",
    default: true,
    accessible: true,
    active: true,
    visibility: ["newsfeed", "calendar"],
    properties: {
      service: ["news"],
      categories: ["domestic"]
    }
  },
  {
    id: "foreign",
    title: "articleCategories:Foreign",
    default: true,
    accessible: true,
    active: true,
    visibility: ["newsfeed", "calendar"],
    properties: {
      service: ["news"],
      categories: ["foreign"]
    }
  },
  {
    id: "sport",
    title: "articleCategories:Sport",
    default: true,
    accessible: true,
    active: true,
    visibility: ["newsfeed", "calendar"],
    properties: {
      service: ["news"],
      categories: ["sport"],
      subcategories: ["NOT Tabeller og resultater", "NOT Rangeringer", "NOT Uttak"]
    }
  },
  {
    id: "sports_results",
    title: "articleCategories:Sports results",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed"],
    properties: {
      service: ["news"],
      categories: ["sport"],
      subcategories: ["Tabeller og resultater", "Rangeringer", "Uttak"]
    }
  },
  {
    id: "culture",
    title: "articleCategories:Culture",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed", "calendar"],
    properties: {
      service: ["news", "special", "culture"],
      searchString:
        "(subjects.reference:01000000) OR (category:(Kultur) OR category:(Kultur og underholdning) OR category:(Showbiz)) OR mediaTopics.reference:(08000000)"
    }
  },
  {
    id: "facts",
    title: "articleCategories:Facts",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed"],
    properties: {
      service: ALL_NEWS_SERVICES,
      subcategories: ["Faktaboks"]
    }
  },
  {
    id: "ntb_tema_small_matter",
    title: "articleCategories:Småstoff",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed"],
    properties: {
      service: NTB_TEMA_NEWS_SERVICES,
      categories: NTB_TEMA_SMALL_MATTER_CATEGORIES
    }
  },
  {
    id: "nynorsk",
    title: "articleCategories:Nynorsk",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed"],
    properties: {
      service: ["npkdirect"]
    }
  },
  {
    id: "prm",
    title: "articleCategories:PRM",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed"],
    properties: {
      service: ["mediation"],
      categories: ["prm"]
    },
    custom: {
      properties: {
        cssClass: "tranquil"
      }
    }
  },
  {
    id: "ir",
    title: "articleCategories:IR",
    default: true,
    accessible: true,
    active: false,
    visibility: ["newsfeed"],
    properties: {
      service: ["mediation"],
      categories: ["ir"]
    },
    custom: {
      properties: {
        cssClass: "tranquil"
      }
    }
  }
];
