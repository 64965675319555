import React, { useEffect } from "react";

import { isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import style from "../assets/scss/components/news-dashboard.module.scss";
import { useHotkey, useHotkeyScope, hotkeys, hotkeyScopes } from "../helpers/hotkeys";
import { selectActiveNewsfeedArticleFilters } from "../store/articleFilter/selector";
import {
  selectDashboardActiveNewsItemColumnId,
  selectDashboardColumnsShallow,
  selectDashboardActiveNewsItem,
  selectDashboardModalNewsItem,
  selectDashboardActiveNewsItemLatestVersionId,
  selectDashboardModalNewsItemLatestVersionId
} from "../store/dashboard/selector";
import {
  setNewsColumns,
  markNewsItemAsActive,
  markNextNewsItemAsActive,
  markPreviousNewsItemAsActive,
  markNextNewsColumnAsActive,
  markPreviousNewsColumnAsActive,
  setModalNewsItem
} from "../store/dashboard/slice";

import ArticleView from "./article/ArticleView";
import ArticleViewModal from "./article/ArticleViewModal";
import NewsColumn from "./NewsColumn";

function NewsDashboard() {
  const dispatch = useDispatch();

  const columns = useSelector(selectDashboardColumnsShallow, isEqual);
  const activeNewsItemColumnId = useSelector(selectDashboardActiveNewsItemColumnId, isEqual);
  const modalNewsItem = useSelector(selectDashboardModalNewsItem, isEqual);
  const activeArticleFilters = useSelector(selectActiveNewsfeedArticleFilters, isEqual);
  const activeNewsItem = useSelector(selectDashboardActiveNewsItem, isEqual);
  const activeNewsItemLatestVersionId = useSelector(
    selectDashboardActiveNewsItemLatestVersionId,
    isEqual
  );
  const modalNewsItemLatestVersionId = useSelector(
    selectDashboardModalNewsItemLatestVersionId,
    isEqual
  );

  useHotkeyScope(hotkeyScopes.ARTICLE);

  useHotkey(hotkeys.NEXT_ARTICLE, () => {
    dispatch(markNextNewsItemAsActive());
    return false;
  });

  useHotkey(hotkeys.PREVIOUS_ARTICLE, () => {
    dispatch(markPreviousNewsItemAsActive());
    return false;
  });

  useHotkey(hotkeys.NEXT_COLUMN, () => {
    dispatch(markNextNewsColumnAsActive());
    return false;
  });

  useHotkey(hotkeys.PREVIOUS_COLUMN, () => {
    dispatch(markPreviousNewsColumnAsActive());
    return false;
  });

  useEffect(() => {
    // Sort active filters according to their order
    const columnIds = [...activeArticleFilters]
      .sort((a, b) =>
        a.custom?.properties?.order > b.custom?.properties?.order
          ? 1
          : b.custom?.properties?.order > a.custom?.properties?.order
          ? -1
          : 0
      )
      .map(filter => filter.id);

    dispatch(setNewsColumns(columnIds));
  }, [dispatch, activeArticleFilters]);

  return (
    <div className={style["dashboard"]}>
      {modalNewsItem.id && (
        <ArticleViewModal
          article={modalNewsItem}
          latestVersionId={modalNewsItemLatestVersionId}
          onClose={() => dispatch(setModalNewsItem({}))}
        />
      )}
      {columns.map(column => {
        return (
          <React.Fragment key={`news-column-${column.id}`}>
            <NewsColumn id={column.id} />
            {activeNewsItemColumnId === column.id && (
              <ArticleView
                article={activeNewsItem}
                latestVersionId={activeNewsItemLatestVersionId}
                onClose={() => dispatch(markNewsItemAsActive({ id: null, columnId: null }))}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default NewsDashboard;
