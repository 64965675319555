import ky from "ky";
import qs from "qs";

import env from "../helpers/env";

export default class BaseService {
  constructor() {
    this.action = "";
    this.query = {};
    this.body = null;
    this.ky = ky;
  }

  static baseURL() {
    switch (env) {
      case "development": {
        return "http://localhost:8080/ntbWeb/";
      }

      case "test": {
        return "https://nyheter.test.ntb.dev/ntbWeb/";
      }

      case "test": {
        return "https://nyheter.test.ntb.dev/";
      }

      case "staging": {
        return "https://nyheter.stage.ntb.dev/ntbWeb/";
      }

      case "production": {
        return "https://nyheter.ntb.no/ntbWeb/";
      }

      case "docker-staging": {
        return "https://ntbnyheter.paulsenit.no/";
      }
    }
  }

  setAction(action = "") {
    this.action = action;
  }

  /**
   * Sets the query parameters of the API request
   * @param {Object} queryObject
   */
  setQuery(queryObject = {}) {
    this.query = queryObject;
  }

  /**
   * Appends the query parameters of the API request
   * @param {object} queryObject
   */
  appendQuery(queryObject = {}) {
    this.query = { ...this.query, ...queryObject };
  }

  setBody(body) {
    this.body = body;
  }

  getApiUrl() {
    let url = BaseService.baseURL();

    if (this.action) url += this.action;
    if (Object.keys(this.query).length) url += `?${qs.stringify(this.query)}`;
    return url;
  }

  changeLocation(options) {
    document.location.href = this.getApiUrl(options);
  }

  /**
   *
   * @return {Promise<string|object|array>}
   */
  async fetch(abortController = undefined) {
    const url = this.getApiUrl();
    let data;

    try {
      const response = await this.ky.get(url, {
        headers: { "X-SpAjaxRequest": true },
        credentials: "include",
        timeout: false,
        signal: abortController ? abortController.signal : null
      });

      if (response.headers.get("content-type").match(/application\/json/g)) {
        data = await response.json();
      } else {
        data = await response.text();
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted due to outdated request.");
      } else {
        console.error("Fetch error:", error);
      }
    }

    return data;
  }

  async post(settings = { method: "POST" }) {
    const url = this.getApiUrl();

    const response = await this.ky(url, {
      method: settings.method.toLowerCase(),
      headers: { "X-SpAjaxRequest": "true" },
      credentials: "include",
      timeout: false,
      json: this.body
    });

    let data;
    if (response.headers.get("content-type")?.match(/application\/json/g)) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    return data;
  }
}
